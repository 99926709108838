import axios from 'axios'
import { matchPath } from 'react-router-dom'
import { LOGOUT } from './types/actions'
import { isApiError } from './utils'
import { LoginResponse, ValidateTokenResponse } from './types/responses'
import { StandardThunkAction } from '../store/store'
import { ResetPasswordResponse, StandardApiResponse } from './types/responses'
import { TokenType } from './types/authentication'

export const login = async(email: string, password: string): Promise<LoginResponse> => {
    try {
        const { data } = await axios.post(`/api/login`, { email, password })
        const { success, path, locationPath, user, details, authorization, code  } = data

        return { success, path, locationPath, user, details, authorization, code }
    }catch (error: any) {
        if(isApiError(error)) {
            const { errors: apiErrors, requiresSubscription, code, token } = error.response?.data || { }
            const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                const item = apiErrors[key] || []

                return [...accum, ...item]
            }, [])

            return { success: false, errors, requiresSubscription, token, code }
        }

        throw error
    }
}

export const registeredLogin = async(sessionId: string): Promise<LoginResponse> => {
    try {
        const { data } = await axios.post(`/api/register/authenticate`, { sessionId })
        const { success, path, locationPath, user, details, authorization, code } = data

        return { success, path, locationPath, user, details, authorization, code }
    }catch (error: any) {
        if(isApiError(error)) {
            const { message } = error.response?.data || { }

            return { success: false, errors: [message] }
        }

        throw error
    }
}

export const sendPasswordResetEmail = (email: string): StandardThunkAction<StandardApiResponse> => {
    return async(): Promise<StandardApiResponse> => {
        try {
            const { data } = await axios.post(`/api/password/email`, { email })
            const { success, message  } = data
    
            
            return { success, message }
        }catch (error: any) {
            if(isApiError(error)) {
                const { errors: apiErrors } = error.response?.data || { }
                
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [])
    
                return { success: false, errors }
            }
    
            throw error
        }
    }
}

export const resetPassword = (email: string, password: string, confirmPassword: string): StandardThunkAction<ResetPasswordResponse> => {
    return async(dispatch): Promise<ResetPasswordResponse> => {
        try {
            const token = getPathsToken()
            const { data } = await axios.post(`/api/password/reset`, { token, email, password, password_confirmation: confirmPassword })
            const { success, errors, authorization } = data

            
            return { success, errors, authorization }
        }catch (error: any) {
            if(isApiError(error)) {
                const { errors: apiErrors } = error.response?.data || { }
                
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
                    if (Array.isArray(item)) {
                        return [...accum, ...item]
                    }
                    return [...accum, item]
                }, [])
    
                return { success: false, errors }
            }
    
            throw error
        }
    }
}

export const getPathsToken = (): string | undefined => {
    const match = matchPath('/password/reset/:token', window.location.pathname);
    const { params } = match || { }
    const { token } = params || { }
  
    return token
}

export const logout = (): StandardThunkAction => {
    return async(dispatch): Promise<void> => {
        try {
            dispatch({ type: LOGOUT })

            window.location.href = '/'
        } catch(e: any) {
            console.log(e)
        }
    }
}

export const validateToken = async(type: TokenType, token: string): Promise<ValidateTokenResponse> => {
    try {
        const { data } = await axios.get(`/api/token/validate?token=${token}&type=${type}`)
        const { success, valid  } = data as ValidateTokenResponse

        return { success, valid }
    }catch (error: any) {
        return { success: false, valid: false }
    }
}



export const updatePassword = (email: string, currentPassword: string, password: string, confirmPassword: string, token?: string): StandardThunkAction<ResetPasswordResponse> => {
    return async(): Promise<ResetPasswordResponse> => {
        try {
            const url = token ? `/api/password/update` : `/api/account/password/update`
            const { data } = await axios.post(url, { email, currentPassword, password, password_confirmation: confirmPassword, token })
            const { success, errors, authorization, path } = data

            
            return { success, errors, authorization, path }
        }catch (error: any) {
            if(isApiError(error)) {
                const { errors: apiErrors = {}, message } = error.response?.data || { }
                
                const errors = Object.keys(apiErrors).reduce((accum: string[], key: string) => {
                    const item = apiErrors[key] || []
    
                    return [...accum, ...item]
                }, [message])
    
                return { success: false, errors }
            }
    
            throw error
        }
    }
}