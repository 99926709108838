import { Component } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from "react-spinners"

import { SummaryItem } from './SummaryItem'
import Card, { SLRBaseProps } from './Card'
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text'
import { TotalDivider } from './TotalDivider'
import { Box, Button as MUIButton } from '@mui/material'
import { calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate, runMontecarloForGraph } from '../../actions/standardLivingRisk'
import { loadModelOptions, loadConfigurationOptions } from '../../actions/portfolioDesigner'
import Chart from '../common/Chart'
import { LooseObject, SelectOption } from '../../common/types'
import { Calculation, FundingType, MontecarloResultSet, NeedsImmunizationPeriod, NeedsPortfolioMethod } from '../../actions/types/standardLivingRisk'
import { Select } from '../common/styled/Select'
import { ReduxState } from '../../reducers'
import { ModelItemOption } from '../../actions/types/portfolioDesigner'
import { addError } from '../../actions/notifications'
import { Link } from 'react-router-dom'
import MessageModal from '../common/MessageModal'
import { BucketingExampleImage } from '../../assets'
import Button from '../common/Button'
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter'
import CommonTable from '../common/CommonTable'
import { formatNumberAsPercentage } from '../../common/utils'
import { exportDataToExcel } from '../../common/excel'

const FIXED_MONEY_GUIDE_INV_PORT_TODAY = 5000000
interface SLRProps extends SLRBaseProps, WithClientParameterProps {
    investmentPortfolioToday: number
    slr: string
    hideHeader: boolean
    hideFooter: boolean
    needsInTimeChart: LooseObject
    wantsInTimeChart: LooseObject
    wishesInTimeChart: LooseObject
    calculatingNeeds: boolean
    needsPortfolio: number
    wantsPortfolio: number
    wishesPortfolio: number
    yearsInFinancialGoal: number;
    yearsToFinancialGoal: number;
    needsWentNegative: boolean
    needsImmunizationPeriod: number;
    needsPortfolioModel: ModelItemOption | null
    wantsPortfolioModel: ModelItemOption | null
    wishesPortfolioModel: ModelItemOption | null
    portfolioModels: SelectOption[]
    financialPlanNeedsModels: SelectOption[]
    financialPlanSource: string
    montecarloResults?: MontecarloResultSet
    bands: any[]
    wantsWishes: any[]
    needsImmunizationPeriodUpdate: (option: SelectOption) => void
    needsPortfolioModelUpdate: (option: ModelItemOption) => void
    wantsPortfolioModelUpdate: (option: ModelItemOption) => void
    wishesPortfolioModelUpdate: (option: ModelItemOption) => void
    loadModelOptions: typeof loadModelOptions,
    loadConfigurationOptions: typeof loadConfigurationOptions,
    calculateNeedsPortfolioInTime: () => void
    addError: typeof addError
}

interface SLRState {
    selectedChartOption: SelectOption | null
    showBucketingExample: boolean
    needsImmunizationPeriod: SelectOption | null
    needsImmunizationPeriodOptions: SelectOption[]
}

export const TREASURY_LADDER_OPTION = { label: 'Treasury Ladder', value: NeedsPortfolioMethod.TREASURY_LADDER };
export const CHART_OPTIONS = [{ label: 'Needs Portfolio', value: FundingType.NEED },{ label: 'Wants Portfolio', value: FundingType.WANT }, { label: 'Wishes Portfolio', value: FundingType.WISH }]
export const NEEDS_IMMUNIZATION_PEROD = [{ label: '5 Year', value: NeedsImmunizationPeriod.FIVE_YEAR }, { label: '10 Year', value: NeedsImmunizationPeriod.TEN_YEAR }]

class SLR extends Component <SLRProps, SLRState> {

    constructor(props: SLRProps) {
        super(props)
        this.props.calculateNeedsPortfolioInTime()
        const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(props.yearsToFinancialGoal, props.yearsInFinancialGoal);
        const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === props.needsImmunizationPeriod) || null
        this.state = {
            selectedChartOption: CHART_OPTIONS[0],
            showBucketingExample: false,
            needsImmunizationPeriodOptions,
            needsImmunizationPeriod,
            
        }
    }
    
    componentDidMount(): void {
        this.props.loadModelOptions();
        this.props.loadConfigurationOptions();
    }

    componentDidUpdate(prevProps: SLRProps) {
        if (prevProps.yearsToFinancialGoal !== this.props.yearsToFinancialGoal || prevProps.yearsInFinancialGoal !== this.props.yearsInFinancialGoal) {
            const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(this.props.yearsToFinancialGoal, this.props.yearsInFinancialGoal);
            const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === this.props.needsImmunizationPeriod) || null
            this.setState({ needsImmunizationPeriodOptions, needsImmunizationPeriod })
        }
        if (prevProps.needsImmunizationPeriod !== this.props.needsImmunizationPeriod) {
            const needsImmunizationPeriodOptions = this.getNeedsImmunizationPeriodOptions(this.props.yearsToFinancialGoal, this.props.yearsInFinancialGoal);
            const needsImmunizationPeriod = needsImmunizationPeriodOptions.find(({ value }) => value === this.props.needsImmunizationPeriod) || null
            this.setState({ needsImmunizationPeriod })
        }
    }


    getNeedsImmunizationPeriodOptions = (yearsToFinancialGoal: number, yearsInFinancialGoal: number) => {
        const totalDuration = yearsToFinancialGoal + yearsInFinancialGoal
        const bucketCount = Math.ceil(totalDuration / 5)
        
        const options = []


        for (let i = 1; i <= bucketCount; i++) {
            options.push({ label: `${i * 5} Year`, value: i * 5 })
        }

        return options;
    }

    setSelectedChartOption = (selectedChartOption: SelectOption) => {
        this.setState({ selectedChartOption })
    }
    setSelectedNeedsImmunizationPeriod = (selectedOption: SelectOption) => {
        this.props.needsImmunizationPeriodUpdate(selectedOption)
    }


    setSelectedNeedsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })

            return
        }
        this.props.needsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWantsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wantsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWishesPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wishesPortfolioModelUpdate(selectedOption)
    }

    onCloseBucketingExample = () => {
        this.setState({ showBucketingExample: false })
    }

    onShowBucketingExample = () => {
        this.setState({ showBucketingExample: true })
    }

    mapCalculationToExcel = (calculations: Calculation[]) => {
        return calculations.map(({ start, inflows, outflows, returnRate, end }, year) => ({
            'Year': year,
            'Starting Balance': start,
            'Inflow': inflows,
            'Outflow': outflows,
            'Return': returnRate > 1 ? returnRate - 1 : returnRate,
            'Ending Balance': end
        }));
    }

    saveToExcel = () => {
        const { selectedChartOption } = this.state;
        if (!selectedChartOption) return;
        let calculations: Calculation[] = []
        if (selectedChartOption.value === FundingType.NEED) {
            calculations = this.props.bands?.find(({ name }) => name.includes('Needs'))?.calculations || []
        }
        if (selectedChartOption.value === FundingType.WANT) {
            calculations = this.props.wantsWishes?.find(({ name }) => name.includes('Wants'))?.calculations || []
        }
        if (selectedChartOption.value === FundingType.WISH) {
            calculations = this.props.wantsWishes?.find(({ name }) => name.includes('Wishes'))?.calculations || []
        }
        const output = this.mapCalculationToExcel(calculations);
        exportDataToExcel('Calculations', output, `Calculations - ${selectedChartOption.label}`)
    }

    // onShowMontecarloGraph = () => {
    //     const { selectedChartOption } = this.state
    //     if (!selectedChartOption) return
        
    //     this.onRunMontecarloForType(selectedChartOption.value as FundingType)
    // }

    // onRunMontecarloForType = async (type: FundingType) => {
    //     this.setState({ showMontecarloGraph: true, monetecarloGraphType: type, runningMontecarloGraph: true, montecarloGraphImage: '' })
    //     try {
    //         const { portfolio_values_graph } = await runMontecarloForGraph(type)
    //         this.setState({ runningMontecarloGraph: false, montecarloGraphImage: portfolio_values_graph })
    //     } catch (e) {
    //         this.props.addError('Error Running Monte Carlo', 'There was an error running the Monte Carlo simulation. Please try again.')
    //         this.setState({ runningMontecarloGraph: false, montecarloGraphImage: '', showMontecarloGraph: false })
    //     }
    // }

    // onCloseMontecarloGraph = () => {
    //     this.setState({ showMontecarloGraph: false, monetecarloGraphType: null, runningMontecarloGraph: false, montecarloGraphImage: '' })
    // }


    render() {
        const { investmentPortfolioToday, needsInTimeChart, wantsInTimeChart, wishesInTimeChart,
                needsWentNegative, needsPortfolio, wantsPortfolio, wishesPortfolio, onComponentSize,
                needsPortfolioModel, calculatingNeeds, wantsPortfolioModel, wishesPortfolioModel,
                portfolioModels, financialPlanNeedsModels, financialPlanSource, clientId, montecarloResults, } = this.props
        const { selectedChartOption } = this.state

        const { needs: montecarloNeeds, wants: montecarloWants, wishes: montecarloWishes } = montecarloResults || {}

        const needsPortfolioExceedsInvestmentPortfolio = (montecarloNeeds?.missing_capitol ?? 0) > 0
        const wishesPortfolioExceedsInvestmentPortfolio = (montecarloWishes?.missing_capitol ?? 0) > 0
        const wantsPortfolioExceedsInvestmentPortfolio = (montecarloWants?.missing_capitol ?? 0) > 0
        const showExceedsInvestmentPortfolioWarning = needsPortfolioExceedsInvestmentPortfolio || wishesPortfolioExceedsInvestmentPortfolio || wantsPortfolioExceedsInvestmentPortfolio
        const shortageAmount = (montecarloNeeds?.missing_capitol ?? 0) + (montecarloWants?.missing_capitol ?? 0) + (montecarloWishes?.missing_capitol ?? 0)
        
        const needsPortfolioDisplay = needsWentNegative || needsPortfolioExceedsInvestmentPortfolio ? '-' : needsPortfolio
        const wantsPortfolioDisplay = needsWentNegative || wantsPortfolioExceedsInvestmentPortfolio ? '-' : wantsPortfolio
        const wishesPortfolioDisplay = needsWentNegative || wishesPortfolioExceedsInvestmentPortfolio? '-' : wishesPortfolio
        const showRawValues = needsWentNegative || needsPortfolioExceedsInvestmentPortfolio;

        const needsSuccessRate = !calculatingNeeds && !needsPortfolioExceedsInvestmentPortfolio && montecarloResults ? formatNumberAsPercentage(montecarloNeeds?.success_rate ?? 0) : '--'
        const wantsSuccessRate = !calculatingNeeds && !wantsPortfolioExceedsInvestmentPortfolio &&  montecarloResults ? formatNumberAsPercentage(montecarloWants?.success_rate ?? 0) : '--'
        const wishesSuccessRate = !calculatingNeeds && !wishesPortfolioExceedsInvestmentPortfolio &&  montecarloResults ? formatNumberAsPercentage(montecarloWishes?.success_rate ?? 0) : '--'

        const isMoneyGuide = financialPlanSource === 'money_guide'
        
        let inTimeChart = undefined
        if (selectedChartOption?.value === FundingType.NEED) {
            inTimeChart = needsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WANT) {
            inTimeChart = wantsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WISH) {
            inTimeChart = wishesInTimeChart
        }

        return (
            <Box sx={{ width: '80rem', display: 'flex', flexDirection: 'row' }}>
                <Card sx={{ width: '72.5rem'}} title={'ALM Risk'} hideHeader hideFooter={this.props.hideFooter} onComponentSize={onComponentSize} showAddProfileHistory={false}>
                    <MessageModal hideHeader title={''} visible={this.state.showBucketingExample} onCancel={this.onCloseBucketingExample} onButtonPress={this.onCloseBucketingExample} >
                        <BucketingExampleImage />
                    </MessageModal>
                    {/* <MessageModal title={'Montecarlo Graph'} visible={this.state.showMontecarloGraph} onCancel={this.onCloseMontecarloGraph} onButtonPress={this.onCloseMontecarloGraph} >
                        {this.state.runningMontecarloGraph && (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: 250 }}>
                                <Text sx={{ mb: '1rem'}}>Loading Montecarlo Graph...</Text>
                                <ClipLoader />
                            </Box>
                        )}
                        {!this.state.runningMontecarloGraph && this.state.montecarloGraphImage && (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: 250 }}>
                                <img src={this.state.montecarloGraphImage} alt={'Montecarlo Graph'} />
                            </Box>
                        )}
                    </MessageModal> */}
                    <Box sx={{ minHeight: '38rem' }}>
                        <SummaryItem label={'Investment Portfolio'} value={investmentPortfolioToday} />
                        <TotalDivider />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Needs Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={financialPlanNeedsModels} value={needsPortfolioModel} onChange={this.setSelectedNeedsPortfolioModel} />
                                    <Select sx={{ width: '15rem', marginLeft: '1rem' }} options={this.state.needsImmunizationPeriodOptions} value={this.state.needsImmunizationPeriod} onChange={this.setSelectedNeedsImmunizationPeriod} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={needsPortfolioDisplay}
                        />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Wants Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={portfolioModels} value={wantsPortfolioModel} onChange={this.setSelectedWantsPortfolioModel} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={wantsPortfolioDisplay}
                        />
                        <SummaryItem
                            label={
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text type={'form_header'} sx={{ width: '16rem' }}>Wishes Portfolio</Text>
                                    <Select sx={{ width: '25rem' }} options={portfolioModels} value={wishesPortfolioModel} onChange={this.setSelectedWishesPortfolioModel} />
                                </Box>
                            }
                            displayRawValue={showRawValues}
                            value={wishesPortfolioDisplay}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Link onClick={this.onShowBucketingExample} to={'#'}>
                                <Text
                                    sx={{ 
                                        display: 'flex',
                                        textDecoration: 'underline',
                                        textAlign: 'center',
                                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                        fontFamily: 'Bowlby One SC',
                                        color: 'navyblue',
                                        
                                    }}>
                                    How Does Bucketing Work?<br /> A Simple Example
                                </Text>
                            </Link>
                            {isMoneyGuide && <Box sx={{ marginTop: '1rem'}}><Text>Source: MoneyGuide</Text></Box> }
                            {!isMoneyGuide &&<Button title={<>Update Savings<br />& Goals</>} href={`/software/clients/${clientId}/plan`} sx={{ minWidth: 'unset', minHeight: 'unset', width: '20rem', height: '4.5rem' }} /> }
                    </Box>
                   
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {needsWentNegative && 
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem', textAlign: 'center' }}>
                            <Text sx={{ color: '#b20000', fontWeight: 'bold' }}>Warning: Needs Can't Be Met. Please Update Your Assumptions.</Text>
                        </Box>
                    }
                    {showExceedsInvestmentPortfolioWarning && shortageAmount &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Text sx={{ color: '#b20000', fontWeight: 'bold', textAlign: 'center', marginTop: '1.5rem' }}>Warning: Goals Can't Be Met.<br />An additional {shortageAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} is required.</Text>
                        </Box>
                    }
                    </Box>
                </Card>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: '1rem', marginLeft: '8rem',  }}>
                        <Text 
                            type='form_header' 
                            sx={{
                                display: 'flex',
                                borderRadius: 0,
                                height: '4rem',
                                padding: '2.5rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '14rem'
                            }}>
                                Success Rate
                        </Text>
                    <Box sx= {{ display: 'flex', flexDirection: 'column', mt: '1.85rem' }}>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.2rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : needsSuccessRate}
                        </Text>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.25rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : wantsSuccessRate}
                        </Text>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.25rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : wishesSuccessRate}
                        </Text>
                    </Box>

                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '5rem',  }}>
                        <Select sx={{ width: '45rem', marginLeft: '1rem' }} options={CHART_OPTIONS} value={selectedChartOption} onChange={this.setSelectedChartOption} />
                        {(calculatingNeeds)&&
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem' }}>
                                <ClipLoader />
                            </Box>
                        }
                        {inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                <Chart id={'needsInTimeChart'} sx={{ width: '45rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={inTimeChart} />
                            </Box>
                        }
                        {!inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Text sx={{ fontWeight: 'bold' }}>No Data Available</Text>
                            </Box>
                        }
                </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk, portfolioDesigner }: ReduxState) => {
    const { investment_portfolio_today: investmentPortfolioToday, standardLivingRisk: slr, calculatingNeeds, 
            needsPortfolio, wantsPortfolio, wishesPortfolio, needsWentNegative, needsInTimeChart, wantsInTimeChart,
            wishesInTimeChart,financial_plan_source, needs_immunization_period, needs_model_id, wants_model_id,
            wishes_model_id, years_in_financial_goal: yearsInFinancialGoal, years_to_financial_goal: yearsToFinancialGoal,
            bands, wantsWishes, montecarloResults } = standardLivingRisk
    const { portfolioModels, financial_plan_needsModels: financialPlanNeedsModels } = portfolioDesigner;
            
    
    return {
        investmentPortfolioToday: financial_plan_source === 'money_guide' ? FIXED_MONEY_GUIDE_INV_PORT_TODAY : investmentPortfolioToday,
        financialPlanSource: financial_plan_source,
        slr,
        calculatingNeeds,
        needsPortfolio,
        wantsPortfolio,
        wishesPortfolio,
        needsInTimeChart,
        wantsInTimeChart,
        wishesInTimeChart,
        needsWentNegative,
        portfolioModels,
        financialPlanNeedsModels,
        yearsInFinancialGoal,
        yearsToFinancialGoal,
        montecarloResults,
        bands,
        wantsWishes,
        needsImmunizationPeriod: needs_immunization_period,
        needsPortfolioModel: financialPlanNeedsModels?.find(({ value }) => value === needs_model_id) || null,
        wantsPortfolioModel: portfolioModels.find(({ value }) => value === wants_model_id) || null,
        wishesPortfolioModel: portfolioModels.find(({ value }) => value === wishes_model_id) || null,

    }
}

export default withClientParameter(connect(mapStateToProps, { calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate, addError, loadModelOptions, loadConfigurationOptions })(SLR as any))