import { PortfolioDesignerConfiguration, ResultResponse } from "./portfolioDesigner"

export interface StandardLivingRiskSimple {
    discount_rate: number
    inflation_rate: number
    pv_savings: number
    pv_liabilities: number
    assets_discreationary_wealth: number
    standardLivingRisk: number
    financial_plan_source: FinancialPlanSource
}
export type IrregularItemType = 'income' | 'expense'
export interface IrregularItem {
    id: number
    description: string | null
    number_of_years: number
    start_year: number
    type: IrregularItemType
    value: number
    funding_type: FundingType
}

export interface UpdateStandardLivingRiskRequest {
    years_to_financial_goal?: number
    years_in_financial_goal?: number
    discount_rate?: number
    inflation_rate?: number
    montecarlo_threshold?: number
    investment_portfolio_today?: number
    current_annual_savings?: number
    planned_annual_savings_increase?: number
    desired_annual_funding?: number
    desired_annual_wants?: number
    desired_annual_wishes?: number
    financial_plan_source?: FinancialPlanSource
    needs_immunization_period?: NeedsImmunizationPeriod
    needs_model_id?: number
    wants_model_id?: number
    wishes_model_id?: number
}

export enum FinancialPlanSource {
    PORTFOLIO_DESIGNER = 'portfolio_designer',
    MONEY_GUIDE = 'money_guide',
}

export enum FundingType {
    NEED = 'need',
    WANT = 'want',
    WISH = 'wish',
}

export enum NeedsPortfolioMethod {
    SIMPLIFY_NEEDS = 'simplify_needs',
    TREASURY_LADDER = 'treasury_ladder',
}

export enum NeedsImmunizationPeriod {
    FIVE_YEAR = 'five_year',
    TEN_YEAR = 'ten_year',
}

export enum WantsPortfolioModel {
    CONSERVATIVE = 'conservative',
    MODERATE = 'moderate',
    GROWTH = 'growth',
}

export enum WishesPortfolioModel {
    SIMPLIFY_WISHES = 'simplify_wishes',
}


export const FUNDING_TYPE_OPTIONS = [{ label: 'Need', value: FundingType.NEED },{ label: 'Want', value: FundingType.WANT }, { label: 'Wish', value: FundingType.WISH }]
export const FINANCIAL_PLAN_OPTIONS = [{ label: 'Portfolio Designer', value: FinancialPlanSource.PORTFOLIO_DESIGNER }, { label: 'Money Guide', value: FinancialPlanSource.MONEY_GUIDE }]

export interface Calculation {
    start: number;
    inflows: number;
    outflows: number;
    returnRate: number;
    end: number;
}


export interface Bucket {
    name: string;
    start: number;
    end?: number;
    discountRate: number;
    expenseFundingType: FundingType;
    lineStyle: string;
    findNeeds?: boolean;
    needsPortfolio?: number;
    needsInvestmentPortfolio?: number;
    needCashFlows?: CashflowVector;
    droppedCashFlows?: CashflowVector;
    calculations?: Calculation[];
  }
  
  export interface CashflowVector {
    [year: number]: number;
  }
export interface MontecarloPercentiles {
    [key: string]: {
      [key: string]: number;
    };
  }
  
export interface MontecarloResponse extends ResultResponse {
    success_rate: number;
    portfolio_values: number[][];
}

export interface MontecarloResult {
    success_rate: number;
    required_capitol: number;
    missing_capitol: number;
    portfolio_values: number[][];
    portfolio_values_graph: string; // Base64 encoded image string
}

export interface MontecarloResultSet {
    needs: MontecarloResult;
    wants: MontecarloResult;
    wishes: MontecarloResult;
}