import React, { Component } from 'react'
import { connect } from 'react-redux'

import { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate, montecarloNeedsThresholdUpdate, montecarloWantsThresholdUpdate, montecarloWishesThresholdUpdate } from '../../actions/standardLivingRisk'
import { LineItem } from './LineItem'
import Card, { SLRBaseProps } from './Card'
import { ReduxState } from '../../reducers'

interface FinancialGoalProps extends SLRBaseProps {
    yearsToFinancialGoalUpdate: () => void,
    yearsOfFinancialGoalUpdate: () => void,
    montecarloNeedsThresholdUpdate: () => void,
    montecarloWantsThresholdUpdate: () => void,
    montecarloWishesThresholdUpdate: () => void,
    discountRateUpdate: () => void,
    inflationRateUpdate: () => void,
    yearsToFinancialGoal: number
    yearsOfFinancialGoal: number
    montecarloNeedsThreshold: number
    montecarloWantsThreshold: number
    montecarloWishesThreshold: number
    discountRate: number
    inflationRate: number
}

class FinancialGoal extends Component <FinancialGoalProps> {
    constructor(props: FinancialGoalProps) {
        super(props)
    }

    render() {
        const { yearsToFinancialGoal, yearsOfFinancialGoal, montecarloNeedsThreshold, montecarloWantsThreshold, montecarloWishesThreshold, onComponentSize } = this.props
        
        return (
            <Card title={'Preliminaries'} onComponentSize={onComponentSize}>
                <LineItem number={1} label={'Years Until Retirement'} value={yearsToFinancialGoal} onLeave={this.props.yearsToFinancialGoalUpdate} />
                <LineItem number={2} label={'Years In Retirement'} value={yearsOfFinancialGoal} onLeave={this.props.yearsOfFinancialGoalUpdate} />
                <LineItem number={3} label={'Target Needs Success Rate'} value={montecarloNeedsThreshold} onLeave={this.props.montecarloNeedsThresholdUpdate} />
                <LineItem number={4} label={'Target Wants Success Rate'} value={montecarloWantsThreshold} onLeave={this.props.montecarloWantsThresholdUpdate} />
                <LineItem number={5} label={'Target Wishes Success Rate'} value={montecarloWishesThreshold} onLeave={this.props.montecarloWishesThresholdUpdate} />
            </Card>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk }: ReduxState) => {
    const { years_to_financial_goal: yearsToFinancialGoal, years_in_financial_goal: yearsOfFinancialGoal, discount_rate: discountRate, inflation_rate: inflationRate, montecarlo_needs_threshold: montecarloNeedsThreshold, montecarlo_wants_threshold: montecarloWantsThreshold, montecarlo_wishes_threshold: montecarloWishesThreshold } = standardLivingRisk
    
    return {
        yearsToFinancialGoal,
        yearsOfFinancialGoal,
        montecarloNeedsThreshold,
        montecarloWantsThreshold,
        montecarloWishesThreshold,
        discountRate,
        inflationRate,
    }
}

export default connect(mapStateToProps, { yearsToFinancialGoalUpdate, yearsOfFinancialGoalUpdate, discountRateUpdate, inflationRateUpdate, montecarloNeedsThresholdUpdate, montecarloWantsThresholdUpdate, montecarloWishesThresholdUpdate })(FinancialGoal as any)