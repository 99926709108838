export const GET_DOMAINS = "GET_DOMAINS"
export const TOKEN = "TOKEN"
export const PROFILE_ID = "PROFILE_ID"
export const PROFILE = "PROFILE"
export const SELECTED_KEY = "SELECTED_KEY"
export const MEMBERSHIP = "MEMBERSHIP"
export const USER_LOADED = "USER_LOADED"
    
export const DOMAIN_LOADED = "DOMAIN_LOADED"
export const PARAMETER_LIST_UPDATED = "PARAMETER_LIST_UPDATED"
export const SELECTED_DOMAIN = "SELECTED_DOMAIN"
export const ASSETS_UPDATED = "ASSETS_UPDATED"

export const TABLE_DETAIL_VALUE_UPDATED = "TABLE_DETAIL_VALUE_UPDATED"
export const ASSETS_VALUE_UPDATED = "ASSETS_VALUE_UPDATED"
export const STAT_VALUE_UPDATED = "STAT_VALUE_UPDATED"

export const PARAMETER_MAPPING_UPDATED = "PARAMETER_MAPPING_UPDATED"
export const PORTFOLIO_MAP_RESET = "PORTFOLIO_MAP_RESET"

export const PORTFOLIO_MAPS_FOR_DOMAIN = "PORTFOLIO_MAPS_FOR_DOMAIN"


export const COMPLETE_QUESTION_UPDATE = "COMPLETE_QUESTION_UPDATE"
export const ANNUAL_INCOME = "ANNUAL_INCOME"
export const RISK_AVERSION = "RISK_AVERSION"
export const LOSS_AVERSION = "LOSS_AVERSION"
export const RISK_PREFERENCE_PAGE_INDEX = "RISK_PREFERENCE_PAGE_INDEX"
export const STANDARD_LIVING_RISK_PAGE_INDEX = "STANDARD_LIVING_RISK_PAGE_INDEX"

export const MEASURED_PARAMETER_UPDATE = 'MEASURED_PARAMETER_UPDATE'
export const TAB_CHANGE = 'TAB_CHANGE'

export const INVESTMENT_PORTFOLIO_UPDATE = 'INVESTMENT_PORTFOLIO_UPDATE'
export const RESET_INVESTMENT_PORTFOLIO_UPDATE = 'RESET_INVESTMENT_PORTFOLIO_UPDATE'
export const RISK_VALUE_CHANGED = 'RISK_VALUE_CHANGED'
export const RISK_PREFERENCES_LOADING = 'RISK_PREFERENCES_LOADING'
export const RISK_PREFERENCES_MEASURED_UPDATE = 'RISK_PREFERENCES_MEASURED_UPDATE'

export const PROFILE_HISTORY_ADDED = 'PROFILE_HISTORY_ADDED'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const RUNNING_PROFILE_SCRIPT = 'RUNNING_PROFILE_SCRIPT'

export const ADVISOR_DASHBOARD_LOADING = 'ADVISOR_DASHBOARD_LOADING'
export const CLIENTS_RETRIEVED = 'CLIENTS_RETRIEVED'
export const LOGO_AVAILABLE = 'LOGO_AVAILABLE'
export const QUERY_SHOW_ADD = 'QUERY_SHOW_ADD'

export const CLIENT_ADDED = 'CLIENT_ADDED'
export const CLIENT_REMOVED = 'CLIENT_REMOVED'
export const CLIENT_EMAILED_SUCCESS = 'CLIENT_EMAILED_SUCCESS'
export const CLIENT_EMAILED_SUCCESS_CLEAR = 'CLIENT_EMAILED_SUCCESS_CLEAR'

export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'

export const STANDARD_LIVING_RISK = "STANDARD_LIVING_RISK"
export const STANDARD_LIVING_RISK_LOADING = "STANDARD_LIVING_RISK_LOADING"
export const STANDARD_LIVING_RISK_RECEIVED = "STANDARD_LIVING_RISK_RECEIVED"

export const YEARS_TO_FINANCIAL_GOAL = 'YEARS_TO_FINANCIAL_GOAL'
export const YEARS_OF_FINANCIAL_GOAL = 'YEARS_OF_FINANCIAL_GOAL'
export const DISCOUNT_RATE = 'DISCOUNT_RATE'
export const INFLATION_RATE = 'INFLATION_RATE'
export const MONTECARLO_THRESHOLD = 'MONTECARLO_THRESHOLD'

export const INVESTMENT_PORTFOLIO_TODAY = 'INVESTMENT_PORTFOLIO_TODAY'
export const CURRENT_ANNUAL_SAVINGS = 'CURRENT_ANNUAL_SAVINGS'
export const PLANNED_ANNUAL_SAVINGS = 'PLANNED_ANNUAL_SAVINGS'

export const DESIRED_ANNUAL_FUNDING = 'DESIRED_ANNUAL_FUNDING'
export const DESIRED_ANNUAL_WANTS = 'DESIRED_ANNUAL_WANTS'
export const DESIRED_ANNUAL_WISHES = 'DESIRED_ANNUAL_WISHES'
export const YEARS_OF_FUNDING = 'YEARS_OF_FUNDING'
export const STANDARD_LIVING_RISK_UPDATE = 'STANDARD_LIVING_RISK_UPDATE'


export const CAPITAL_MARKET_ASSUMPTIONS = "CAPITAL_MARKET_ASSUMPTIONS"
export const CAPITAL_MARKET_ASSUMPTIONS_LOADING = "CAPITAL_MARKET_ASSUMPTIONS_LOADING"
export const CAPITAL_MARKET_ASSUMPTIONS_RECEIVED = "CAPITAL_MARKET_ASSUMPTIONS_RECEIVED"

export const CMA_DATES = "CMA_DATES"
export const SLR_PAGE_INDEX = 'SLR_PAGE_INDEX'
export const RUNNING_CUSTOM_CMA_SCRIPT = 'RUNNING_CUSTOM_CMA_SCRIPT'
export const ON_ASSET_SET_CHANGED = 'ON_ASSET_SET_CHANGED'

export const PROFILE_HISTORY_LOADING = 'PROFILE_HISTORY_LOADING'
export const PROFILE_HISTORY_RECEIVED = 'PROFILE_HISTORY_RECEIVED'
export const SHOW_ADD_HISTORY_SUCCESS = 'SHOW_ADD_HISTORY_SUCCESS'

export const SHOW_NOTES_MODAL = 'SHOW_NOTES_MODAL'
export const HIDE_NOTES_MODAL = 'HIDE_NOTES_MODAL'

export const SHOW_USER_UTILITIES = 'SHOW_USER_UTILITIES'
export const HIDE_USER_UTILITIES = 'HIDE_USER_UTILITIES'

export const CLIENT_DATA_RETRIEVED = 'CLIENT_DATA_RETRIEVED'
export const RISK_LEVEL_AVERAGES = 'RISK_LEVEL_AVERAGES'

export const ASSET_SET_ID_CHANGED = 'ASSET_SET_ID_CHANGED'
export const CLIENT_DETAILS_COMPLETED = 'CLIENT_DETAILS_COMPLETED'

export const FIRM_WEBSITE = 'FIRM_WEBSITE'


export const NOTIFICATION_ADDED = 'NOTIFICATION_ADDED'
export const NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED'
export const NOTIFICATION_REMOVED = 'NOTIFICATION_REMOVED'

export const SET_ERROR = 'SET_ERROR'
export const CLIENT_LOGS_ERROR = 'CLIENT_LOGS_ERROR'
export const CAPITAL_MARKET_ASSUMPTIONS_ERROR = 'CAPITAL_MARKET_ASSUMPTIONS_ERROR'
export const LOGOUT = 'LOGOUT'
export const SESSION = 'SESSION'
export const SESSION_LOADING = 'SESSION_LOADING'
export const SESSION_LOADING_DONE = 'SESSION_LOADING_DONE'
export const PROFILE_RESET = 'PROFILE_RESET'

export const PAGE_CHANGE = 'PAGE_CHANGE'

export const INVESTMENT_POLICY_STATEMENT_RECEIVED = "INVESTMENT_POLICY_STATEMENT_RECEIVED"

export const ABSOLUTE_RISK = "ABSOLUTE_RISK"
export const RELATIVE_RISK = "RELATIVE_RISK"
export const LIQUIDITY = "LIQUIDITY"
export const TAX_CONCERNS = "TAX_CONCERNS"
export const LEGAL_AND_REGULATORY_FACTORS = "LEGAL_AND_REGULATORY_FACTORS"
export const UNIQUE_CIRCUMSTANCES = "UNIQUE_CIRCUMSTANCES"
export const TIME_HORIZON = "TIME_HORIZON"

export const PRINT_DETAILS_RECEIVED = "PRINT_DETAILS_RECEIVED"
export const NAVBAR_HEIGHT = "NAVBAR_HEIGHT"
export const CLEAR_REGISTER_NAV_STATE = "CLEAR_REGISTER_NAV_STATE"


export const ACCOUNT = "ACCOUNT"
export const AUTH_TOKENS = 'AUTH_TOKENS'
export const USERS_RECEIVED = 'USERS_RECEIVED'

export const ACTIVATION_CODES_RECEIVED = 'ACTIVATION_CODES_RECEIVED'


export const COPY_ITEMS = 'COPY_ITEMS'
export const EDIT_COPY_ITEM = 'EDIT_COPY_ITEM'


export const GUIDE_COPY_ITEMS = 'GUIDE_COPY_ITEMS'
export const SECTION_GUIDE_COPY_ITEMS = 'SECTION_GUIDE_COPY_ITEMS'

export const GUIDES = 'GUIDES'
export const LOGO_UPDATE = 'LOGO_UPDATE'

export const PORTFOLIO_UPDATED = 'PORTFOLIO_UPDATED'
export const REBALANCING_FREQUENCY_UPDATED = 'REBALANCING_FREQUENCY_UPDATED'
export const ADVISORY_FEE_UPDATED = 'ADVISORY_FEE_UPDATED'
export const CONFIGS_UPDATED = 'CONFIGS_UPDATED'
export const MODELS_UPDATED = 'MODELS_UPDATED'
export const ANALYZE_RESET = 'ANALYZE_RESET'
export const ANALYZE_UPDATE = 'ANALYZE_UPDATE'


export const PORTFOLIO_MANAGER_MODELS_UPDATED = 'PORTFOLIO_MANAGER_MODELS_UPDATED'
export const PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED = 'PORTFOLIO_MANAGER_SELECTED_MODEL_UPDATED'
export const PORTFOLIO_MANAGER_UPDATE = 'PORTFOLIO_MANAGER_UPDATE'

export const FORECAST_UPDATED = 'FORECAST_UPDATED'

export const FINANCIAL_PLAN_ASSUMPTIONS = 'FINANCIAL_PLAN_ASSUMPTIONS'
export const FINANCIAL_PLAN_ASSUMPTION_INFLATION_RATE = 'FINANCIAL_PLAN_ASSUMPTION_INFLATION_RATE'
export const FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS = 'FINANCIAL_PLAN_ASSUMPTIONS_CUSTOM_MODELS'
export const FINANCIAL_PLAN_ASSUMPTIONS_UPDATE = 'FINANCIAL_PLAN_ASSUMPTIONS_UPDATE'

export const DUE_DILIGENCE_CHARTS = 'DUE_DILIGENCE_CHARTS'
export const DUE_DILIGENCE_CHARTS_SELECTED = 'DUE_DILIGENCE_CHARTS_SELECTED'
export const DUE_DILIGENCE_CHARTS_LOADING = 'DUE_DILIGENCE_CHARTS'

export const DUE_DILIGENCE_CHART_ANNOTATIONS = 'DUE_DILIGENCE_CHART_ANNOTATIONS'
export const DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS = 'DUE_DILIGENCE_CHARTS_SELECTED_ANNOTATIONS'

export const DUE_DILIGENCE_RESULTS = 'DUE_DILIGENCE_RESULTS'
export const DUE_DILIGENCE_CHART_PROCESSING = 'DUE_DILIGENCE_CHART_PROCESSING'


export const DUE_DILIGENCE_TICKERS = 'DUE_DILIGENCE_TICKERS'
export const DUE_DILIGENCE_ADD_TICKER = 'DUE_DILIGENCE_TICKERS'

export const MARKET_DASHBOARD_CHARTS = 'MARKET_DASHBOARD_CHARTS'
export const HISTORICAL_CURVE_CHART = 'HISTORICAL_CURVE_CHART'
export const HISTORICAL_TIME_SERIES_CHART = 'HISTORICAL_TIME_SERIES_CHART'
export const PERFORMANCE_DATA = 'PERFORMANCE_DATA'


export const PERFORMANCE_ATTRIBUTION_LOADING = 'PERFORMANCE_ATTRIBUTION_LOADING'
export const PERFORMANCE_ATTRIBUTION_RECEIVED = 'PERFORMANCE_ATTRIBUTION_RECEIVED'


export const SIMPLIFY_RISK_ANALYZER_LOADING = 'RISK_ANALYZER_LOADING'
export const SIMPLIFY_RISK_ANALYZER_LOADING_USER_INPUTS = 'RISK_ANALYZER_LOADING_USER_INPUTS'
export const SIMPLIFY_RISK_ANALYZER_LOADING_RISK_OUTPUT = 'RISK_ANALYZER_LOADING_RISK_OUTPUT'
export const SIMPLIFY_RISK_ANALYZER_RECEIVED = 'RISK_ANALYZER_RECEIVED'


export const DUE_DILIGENCE_SCREENER_PROCESSING = 'DUE_DILIGENCE_SCREENER_PROCESSING'
export const DUE_DILIGENCE_SCREENER_UPDATE = 'DUE_DILIGENCE_SCREENER_UPDATE'
export const DUE_DILIGENCE_UPDATE = 'DUE_DILIGENCE_UPDATE'